import { SVGLogo } from '../Logo/SVGLogo';
import React from 'react';
import {
  Box,
  Flex,
  HStack,
  useColorMode,
  useColorModeValue,
} from '@chakra-ui/react';
import { HN_ORANGE } from '../../styles/theme';
import { Link } from 'react-router-dom';
import { ThemeToggle } from './ThemeToggle';

export const Header = (props: { starredPage: boolean }) => {
  const { starredPage } = props;
  const { colorMode, toggleColorMode } = useColorMode();

  return (
    <Flex
      width={'full'}
      paddingBottom={'61px'}
      paddingTop={'49px'}
      justifyContent={'space-between'}
    >
      <Flex
        justifyContent="flex-start"
        alignItems={'flex-end'}
        columnGap={'2.25rem'}
      >
        <SVGLogo textColor={useColorModeValue('black', 'white')} />
        <HStack>
          <Box
            fontWeight={!starredPage ? 'bold' : undefined}
            color={!starredPage ? HN_ORANGE : undefined}
          >
            <Link to={'/'}>latest</Link>
          </Box>
          <Box>|</Box>
          <Box
            fontWeight={starredPage ? 'bold' : undefined}
            color={starredPage ? HN_ORANGE : undefined}
          >
            <Link to={'/starred'}>starred</Link>
          </Box>
        </HStack>
      </Flex>
      <ThemeToggle
        setThemeLight={toggleColorMode}
        isLightTheme={colorMode === 'light'}
      />
    </Flex>
  );
};
