import axios from 'axios';
import { IHackerNewsArticle } from '../../components/Article/interfaces';

export const BATCH_SIZE = 12;

export async function fetchTopHackerNewsPosts(): Promise<string[]> {
  try {
    const response = await axios.get(
      'https://hacker-news.firebaseio.com/v0/topstories.json'
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching top Hacker News posts:', error);
    return []; // or handle the error as per your app's requirements
  }
}

export async function fetchHackerNewsArticleBatch(
  articleIds: string[],
  batchNumber: number
): Promise<IHackerNewsArticle[]> {
  const topStoriesPromises = articleIds
    .slice(BATCH_SIZE * batchNumber, (batchNumber + 1) * BATCH_SIZE)
    .map(async (id: any) => {
      const res = await axios.get(
        `https://hacker-news.firebaseio.com/v0/item/${id}.json`
      );
      return res.data;
    });

  return await Promise.all(topStoriesPromises);
}
