import { WritableDraft } from 'immer/dist/internal';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const topArticles = createSlice({
  name: 'topArticles',
  initialState: {
    topArticles: [],
  },
  reducers: {
    setTopArticles: (
      state: WritableDraft<{
        topArticles: string[];
      }>,
      action: PayloadAction<string[]>
    ) => {
      state.topArticles = [...action.payload];
    },
    clearTopArticles: (
      state: WritableDraft<{
        topArticles: string[];
      }>
    ) => {
      state.topArticles = [];
    },
  },
});
export const { setTopArticles, clearTopArticles } = topArticles.actions;
export default topArticles.reducer;
