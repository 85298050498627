export function extractRootDomain(url: string): string | null {
  try {
    const urlObj = new URL(url);
    let hostname = urlObj.hostname;
    const hostnameParts = hostname.split('.');
    const lastIndex = hostnameParts.length - 1;
    let rootDomain =
      hostnameParts[lastIndex - 1] + '.' + hostnameParts[lastIndex];

    if (
      hostnameParts[lastIndex - 1] === 'co' ||
      (hostnameParts[lastIndex - 1] === 'com' && hostnameParts.length > 2)
    ) {
      rootDomain = hostnameParts[lastIndex - 2] + '.' + rootDomain;
    }

    return rootDomain;
  } catch (error: any) {
    console.error('Invalid URL:', error.message);
    return null;
  }
}
