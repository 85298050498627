import React, { useCallback, useMemo } from 'react';
import { IArticle } from './interfaces';
import {
  Box,
  Flex,
  Link,
  Spacer,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { starArticle, unStarArticle } from '../../redux/slices/starredSlice';
import { IStore } from '../../redux/store';
import { IconStar } from '../Star/IconStar';
import { SUBTITLE_TEXT_COLOR } from '../../styles/theme';

interface IArticleProps extends IArticle {
  number: number;
}

export const Article = (props: IArticleProps) => {
  const {
    title,
    id,
    url,
    author,
    points,
    num_comments,
    created_at,
    number,
    rootDomain,
  } = props;
  const starredArticles = useSelector((state: IStore) => state?.starred);
  const dispatch = useDispatch();
  const toggleStar = useCallback(() => {
    if (starredArticles && starredArticles[id]) {
      dispatch(unStarArticle(props));
    } else {
      dispatch(starArticle(props));
    }
  }, [starredArticles, id]);
  const starLabel = useMemo(() => {
    if (starredArticles && starredArticles[id]) {
      return 'saved';
    }
    return 'save';
  }, [starredArticles, id]);
  const timeAgo = new Date(created_at).toLocaleString();
  const comments =
    !num_comments || num_comments === 0
      ? 'no comments'
      : num_comments === 1
        ? '1 comment'
        : `${num_comments} comments`;
  return (
    <Box paddingY={2} width="full" display={'flex'} columnGap={'0.25rem'}>
      <Box
        color={useColorModeValue(
          SUBTITLE_TEXT_COLOR.light,
          SUBTITLE_TEXT_COLOR.dark
        )}
        paddingTop={'0.09rem'}
      >
        {number}.{' '}
      </Box>
      <Box>
        <Box display={'flex'} flexDirection={'row'} alignItems={'flex-end'}>
          <Link href={url} isExternal fontSize="lg" fontWeight="bold">
            {title}
          </Link>
          <Link
            href={`https://news.ycombinator.com/from?site=${rootDomain}`}
            fontFamily={"'Open Sans', sans-serif"}
            fontSize={'small'}
            color={useColorModeValue(
              SUBTITLE_TEXT_COLOR.light,
              SUBTITLE_TEXT_COLOR.dark
            )}
            style={{
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: 'normal',
              paddingBottom: '4px',
              paddingLeft: '4px',
            }}
          >
            {rootDomain && `(${rootDomain})`}
          </Link>
        </Box>
        <Flex align="center" marginTop={1}>
          <Flex width={'max-content'}>
            <Text
              fontFamily={"'Open Sans', sans-serif"}
              fontSize={'small'}
              color={useColorModeValue(
                SUBTITLE_TEXT_COLOR.light,
                SUBTITLE_TEXT_COLOR.dark
              )}
              style={{
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: 'normal',
              }}
            >
              {points} points by {author} {timeAgo} |{' '}
              <Link href={`https://news.ycombinator.com/item?id=${id}`}>
                {comments}
              </Link>{' '}
              |
            </Text>
          </Flex>
          <Text
            fontFamily={"'Open Sans', sans-serif"}
            fontSize={'small'}
            color={useColorModeValue(
              SUBTITLE_TEXT_COLOR.light,
              SUBTITLE_TEXT_COLOR.dark
            )}
            style={{
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: 'normal',
              paddingLeft: '4px',
            }}
          >
            <Link onClick={toggleStar}>
              <Flex
                width={'max-content'}
                alignItems={'center'}
                columnGap={'0.15rem'}
              >
                <IconStar
                  starred={starredArticles[id]}
                  color={useColorModeValue(
                    SUBTITLE_TEXT_COLOR.light,
                    SUBTITLE_TEXT_COLOR.dark
                  )}
                />{' '}
                {starLabel}
              </Flex>
            </Link>
          </Text>
          <Spacer />
        </Flex>
      </Box>
    </Box>
  );
};
