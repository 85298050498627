import React from 'react';
import { Article } from './Article/Article';
import {
  Box,
  Button,
  Container,
  Flex,
  HStack,
  Link as ChakraLink,
  Text,
  useBreakpointValue,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../redux/store';
import { Header } from './Header/Header';
import {
  BATCH_SIZE,
  fetchHackerNewsArticleBatch,
} from '../logic/fetching/fetchArticles';
import { convertHNtoArticle } from '../logic/format/convertHNtoArticle';
import { addArticle } from '../redux/slices/articleSlice';
import { Link } from 'react-router-dom';

export const AppContent = (props: { starredPage: boolean }) => {
  const articles = useSelector((state: IStore) => state?.articles);
  const topArticles = useSelector((state: IStore) => state?.topArticles);
  const starred = useSelector((state: IStore) => state?.starred);
  const dispatch = useDispatch();
  const containerWidth = useBreakpointValue({
    base: 'full',
    sm: 'full',
    md: 'container.md',
    lg: 'container.lg',
    xl: 'container.xl',
  });

  return (
    <Box
      p={4}
      style={{
        flexDirection: 'row',
        alignContent: 'flex-start',
      }}
      backgroundColor={useColorModeValue('white', '#1E2025')}
    >
      <VStack spacing={8}>
        <Container maxW={containerWidth} centerContent>
          <Header starredPage={props.starredPage} />
          {topArticles?.topArticles &&
            topArticles.topArticles.map((articleId, idx) => {
              const article = articles[articleId];
              if (!article || (props.starredPage && !starred[articleId])) {
                return null;
              }
              return <Article {...article} number={idx + 1} key={article.id} />;
            })}
          <Box paddingY={2} width="full" marginTop={'26px'}>
            <Button
              hidden={props.starredPage}
              bgColor={'#FE7139'}
              onClick={() => {
                const batchNumber = Math.floor(
                  Object.keys(articles).length / BATCH_SIZE
                );
                fetchHackerNewsArticleBatch(
                  topArticles.topArticles,
                  batchNumber
                ).then((data) => {
                  data?.forEach((article) => {
                    const formattedArticle = convertHNtoArticle(article);
                    dispatch(addArticle(formattedArticle));
                  });
                });
              }}
            >
              show more
            </Button>
          </Box>
          <Box width="full" height="2px" bg="#FE7139" marginTop={'53px'} />
          <Flex
            flexDirection={'column'}
            justifyContent={'center'}
            alignItems={'center'}
            marginTop={'27px'}
            rowGap={'12px'}
            paddingBottom={'29px'}
          >
            <ChakraLink
              href={'https://github.com/henrymound'}
              fontWeight={'bold'}
              fontSize={'lg'}
              style={{
                fontFamily: "'Open Sans', sans-serif",
              }}
            >
              Hacker News
            </ChakraLink>
            <HStack>
              <Link to={'/'}>
                <ChakraLink>latest</ChakraLink>
              </Link>
              <Text>|</Text>
              <Link to={'/starred'}>
                <ChakraLink>starred</ChakraLink>
              </Link>
            </HStack>
          </Flex>
        </Container>
      </VStack>
    </Box>
  );
};
