import * as React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from './redux/store';
import { Box, ChakraProvider, VStack } from '@chakra-ui/react';
import { AppContent } from './components/AppContent';
import { HN_ORANGE, theme } from './styles/theme';
import { Route, Routes } from 'react-router-dom';
import {
  fetchHackerNewsArticleBatch,
  fetchTopHackerNewsPosts,
} from './logic/fetching/fetchArticles';
import { convertHNtoArticle } from './logic/format/convertHNtoArticle';
import { addArticle } from './redux/slices/articleSlice';
import { setTopArticles } from './redux/slices/topArticlesSlice';

function App() {
  const dispatch = useDispatch();
  const topArticles = useSelector((state: IStore) => state?.topArticles);
  useEffect(() => {
    if (topArticles?.topArticles && topArticles.topArticles.length > 0) {
      return;
    }
    fetchTopHackerNewsPosts().then((data) => {
      dispatch(setTopArticles(data));
      fetchHackerNewsArticleBatch(data, 0).then((data) => {
        data?.forEach((article) => {
          const formattedArticle = convertHNtoArticle(article);
          dispatch(addArticle(formattedArticle));
        });
      });
    });
  }, []);
  return (
    <ChakraProvider theme={theme}>
      <VStack>
        <Box width="full" height="4px" bg={HN_ORANGE} />
      </VStack>
      <Routes>
        <Route index element={<AppContent starredPage={false} />} />
        <Route path="starred" element={<AppContent starredPage={true} />} />
      </Routes>
    </ChakraProvider>
  );
}

export default App;
