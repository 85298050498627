import {
  IArticle,
  IHackerNewsArticle,
} from '../../components/Article/interfaces';
import { extractRootDomain } from './extractRootDomain';

export const convertHNtoArticle = (hnData: IHackerNewsArticle): IArticle => {
  return {
    id: hnData.id.toString(),
    title: hnData.title,
    url: hnData.url,
    points: hnData.score,
    created_at: hnData.time,
    num_comments: hnData.descendants,
    author: hnData.by,
    rootDomain: extractRootDomain(hnData.url),
  };
};
