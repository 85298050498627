import { configureStore } from '@reduxjs/toolkit';
import articleReducer from './slices/articleSlice';
import starredReducer from './slices/starredSlice';
import topArticlesReducer from './slices/topArticlesSlice';
import { IArticle } from '../components/Article/interfaces';

export interface IStore {
  articles: { [key: string]: IArticle };
  starred: { [key: string]: boolean };
  topArticles: { topArticles: string[] };
}

export default configureStore({
  reducer: {
    articles: articleReducer,
    starred: starredReducer,
    topArticles: topArticlesReducer,
  },
});
