import { extendTheme } from '@chakra-ui/react';

export const HN_ORANGE = '#FE7139';
export const SUBTITLE_TEXT_COLOR = {
  dark: 'rgba(255, 255, 255, 0.50)',
  light: 'rgba(0, 0, 0, 0.50)',
};

export const theme = extendTheme({
  config: {
    initialColorMode: 'light',
    useSystemColorMode: true,
  },

  components: {
    Button: {
      baseStyle: {
        fontWeight: 'regular',
        borderRadius: '3px',
      },
      variants: {
        solid: ({ colorMode }: { colorMode: string }) => ({
          bg: colorMode === 'dark' ? '#1E2025' : '#FE7139', // bg color depends on color mode
          color: 'white',
          _hover: {
            bg: colorMode === 'dark' ? '#1E2025' : '#e6623e', // darken the color slightly on hover
          },
        }),
      },
    },
  },
  fonts: {
    body: "'Ubuntu Mono', monospace",
    heading: "'Ubuntu Mono', monospace",
    description: "'Open Sans', sans-serif",
  },
  colors: {
    brand: {
      100: '#f7fafc',
      900: '#1E2025',
    },
  },
});
