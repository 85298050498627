import {WritableDraft} from "immer/dist/internal";
import {IArticle} from "../../components/Article/interfaces";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export const articleSlice = createSlice({
    name: 'articles',
    initialState: {},
    reducers: {
        addArticle: (state: WritableDraft<{
            [key: string]: IArticle;
        }>, action: PayloadAction<IArticle>) => {
            state[action.payload.id] = action.payload
        },
        removeArticle: (state: WritableDraft<{ [key: string]: IArticle }>, action: PayloadAction<string>) => {
            delete state[action.payload];
        }
    },
})
export const {addArticle, removeArticle} = articleSlice.actions;
export default articleSlice.reducer;