import { WritableDraft } from 'immer/dist/internal';
import { IArticle } from '../../components/Article/interfaces';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const starredSlice = createSlice({
  name: 'starred',
  initialState: {},
  reducers: {
    starArticle: (
      state: WritableDraft<{
        [key: string]: boolean;
      }>,
      action: PayloadAction<IArticle>
    ) => {
      state[action.payload.id] = true;
    },
    unStarArticle: (
      state: WritableDraft<{
        [key: string]: boolean;
      }>,
      action: PayloadAction<IArticle>
    ) => {
      delete state[action.payload.id];
    },
  },
});
export const { unStarArticle, starArticle } = starredSlice.actions;
export default starredSlice.reducer;
